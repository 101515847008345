<template>
  <div class="d-flex-column mw-md main">
    <h3 class="mb-0">Registration Summary</h3>
    <CheckOutItemsCard v-for="rego in completed" :key="rego.localId" :rego="rego" :title="rego.profile && rego.profile.participantForm
      ? rego.profile.participantForm.firstName + ' ' + rego.profile.participantForm.lastName
      : ''
      " :email="rego.profile.participantForm.email" :subtitle="rego.entity.name" :entityId="rego.entity._id"
      :showDiscountOptions="true" :lines="checkoutLines(rego)" :addon="getNotEmptyAddonsForRego(rego)"
      :discounts="getRegoDiscounts(rego)" />
    <h3 class="mt-2 mb-0">Payment Summary</h3>
    <CheckOutItemsCard key="checkoutTotal" :total="true" :lines="registrationTotal" :addon="addonTotal"
      :discounts="discountTotal" :checkoutTotal="checkoutTotal" />

    <el-card>
      <CheckBoxLine v-model="termsAccepted" id="checkTerms">
        <p v-if="containsNrl">
          I understand that by registering with NRL I have agreed to the
          <a href="https://www.playrugbyleague.com/terms-and-conditions/" target="_blank">Terms and Conditions</a>
          of participation. <span v-if="containsNrl && containsTfa">and;</span>
        </p>
        <p v-if="containsTfa">
          I understand that by registering with TFA I have agreed to the
          <a href="https://touchfootball.com.au/policies/tfa-membership-and-participant-declaration-ts-and-cs/"
            target="_blank">
            Terms and Conditions
          </a>
          of participation.
        </p>
      </CheckBoxLine>
    </el-card>
    <div v-if="checkoutTotal > 0" id="creditCard">
      <div class="navigation-card-heading">Continue to payment</div>
      <LongLink text="Credit Card" @go="choose('creditcard')" />
      <LongLink v-if="!paymentReq" text="Complete Registration" @go="choose('paynotreq')" />
    </div>
    <div v-else>
      <div class="navigation-card-heading">No payment needed</div>
      <LongLink text="Complete Registration" @go="choose('none')" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import LongLink from "@/components/LongLink.vue";
import CheckOutItemsCard from "@/components/elements/CheckOutItemsCard.vue";
import CheckBoxLine from "@/components/form/fields/CheckBoxLine.vue";
import msg from "@/utils/constants/msg";
import { getNotEmptyAddonsForRego, totalAddonsCheckout } from "@/utils/registration/addon";
import {
  totalDiscountsLevel,
  totalRegistrationsCheckout,
  totalCheckout,
  activeKidsVoucherDiscounts,
  programSettingsDiscountVoucherDiscounts,
  programDiscountVoucherDiscounts,
  clubDiscountVoucherDiscounts,
  associationDiscountVoucherDiscounts,
} from "@/utils/registration/pricing";

export default {
  name: "Payment",
  components: {
    CheckOutItemsCard,
    LongLink,
    CheckBoxLine,
  },
  computed: {
    ...mapGetters("registration", ["completed", "finishedRegoList"]),
    paymentReq() {
      let payment = false;
      this.completed.forEach((registration) => {
        const { priceItem } = registration;
        // Club allows no payment
        if (priceItem.onlinePaymentRequired) payment = true;
        // still need to check the saleable items
        // Check for items or prices at other lvls
        Object.entries(priceItem.pricing).forEach(([category, item]) => {
          // check for all saleable items
          if (priceItem.pricing[`${category}`]["saleableItems"]) {
            // loop thru salebale items
            for (const saleable of priceItem.pricing[`${category}`]["saleableItems"]) {
              if (saleable.price > 0 && saleable.qty > 0) payment = true;
            }
          }
          // Always exclude base level categories such as club and competition
          if (category !== "club" && category !== "competition") {
            // Check if price > 0
            if (item.price > 0 && !item.onlinePaymentRequired) payment = true;
            // Check if item qty > 0
          }
        });
      });
      return payment;
    },
    checkoutTotal() {
      // Calc Total Checkout Price

      return totalCheckout(this.completed);
    },
    registrationTotal() {
      // Calc Total Checkout Price
      const total = totalRegistrationsCheckout(this.completed);
      // Pseudo Item - Subtotal of all registrations
      const pseudoRegoItem = {
        id: "totalRego",
        name: "Season Registration",
        qty: 0,
        subtotal: total,
      };
      return [pseudoRegoItem];
    },
    addonTotal() {
      // Calc Total Addon Price
      const total = totalAddonsCheckout(this.completed);
      // Pseudo Item - Subtotal of all registrations addons
      const pseudoAddonItem = {
        id: "totalAddon",
        name: "Saleable Items",
        qty: 0,
        subtotal: total,
      };
      return pseudoAddonItem.subtotal !== 0 ? [pseudoAddonItem] : [];
    },
    discountTotal() {
      // Calc Total Addon Price
      const total = this.completed.reduce(
        (acc, rego) =>
          acc +
          Object.keys(rego.priceItem.pricing).reduce(
            (acc1, levelName) => acc1 + totalDiscountsLevel(rego, this.completed, levelName),
            0
          ),
        0
      );
      const result = [];
      // Pseudo Item - Subtotal of all registrations addons
      const pseudoAddonItem = {
        isDiscount: true,
        id: "totalDiscounts",
        name: "Registration Discounts",
        qty: 0,
        subtotal: total,
      };

      const pseudoActiveKidItem = this.completed.reduce(
        (acc, rego) => {
          const activeKids = activeKidsVoucherDiscounts(rego, this.completed);
          const activeKidsVouchersUsed = rego.activeKidsVoucher && Array.isArray(rego.activeKidsVoucher) && rego.activeKidsVoucher.length ? rego.activeKidsVoucher.length : 1
          return activeKids
            ? Object.assign({}, acc, { qty: acc.qty + activeKidsVouchersUsed, subtotal: acc.subtotal + activeKids })
            : acc;
        },
        {
          isDiscount: true,
          id: "totalActiveKid",
          name: "Active Kids Voucher",
          qty: 0,
          subtotal: 0,
        }
      );

      const pseudoClubDiscount = this.completed.reduce(
        (acc, rego) => {
          const clubDiscounts = clubDiscountVoucherDiscounts(rego, this.completed);
          return clubDiscounts
            ? Object.assign({}, acc, { qty: acc.qty + 1, subtotal: acc.subtotal + clubDiscounts })
            : acc;
        },
        {
          isDiscount: true,
          id: "totalClubDiscount",
          name: "Club Discount Code",
          qty: 0,
          subtotal: 0,
        }
      );

      const pseudoAssociationDiscount = this.completed.reduce(
        (acc, rego) => {
          const associationDiscounts = associationDiscountVoucherDiscounts(rego, this.completed);
          if (rego.entity.orgtree.national._id === 31) {
            return associationDiscounts
              ? Object.assign({}, acc, {
                qty: acc.qty + 1,
                subtotal:
                  associationDiscounts > pseudoActiveKidItem.subtotal
                    ? associationDiscounts - pseudoActiveKidItem.subtotal
                    : associationDiscounts,
              })
              : acc;
          }
          return associationDiscounts
            ? Object.assign({}, acc, {
              qty: acc.qty + 1,
              subtotal: acc.subtotal + associationDiscounts,
            })
            : acc;
        },
        {
          isDiscount: true,
          id: "totalAssociationDiscount",
          name: "Association Discount Code",
          qty: 0,
          subtotal: 0,
        }
      );

      const pseudoProgramSettingsDiscount = this.completed.reduce(
        (acc, rego) => {
          const programSettingsDiscounts = programSettingsDiscountVoucherDiscounts(
            rego,
            this.completed
          );
          return programSettingsDiscounts
            ? Object.assign({}, acc, {
              qty: acc.qty + 1,
              subtotal: acc.subtotal + programSettingsDiscounts,
            })
            : acc;
        },
        {
          isDiscount: true,
          id: "totalProgramSettingsDiscount",
          name: "Program Discount Code",
          qty: 0,
          subtotal: 0,
        }
      );

      const pseudoProgramDiscount = this.completed.reduce(
        (acc, rego) => {
          const programDiscounts = programDiscountVoucherDiscounts(rego, this.completed);
          return programDiscounts
            ? Object.assign({}, acc, {
              qty: acc.qty + 1,
              subtotal: acc.subtotal + programDiscounts,
            })
            : acc;
        },
        {
          isDiscount: true,
          id: "totalProgramDiscount",
          name: "Program Discount Code",
          qty: 0,
          subtotal: 0,
        }
      );

      if (pseudoAddonItem.subtotal !== 0) result.push(pseudoAddonItem);
      if (pseudoActiveKidItem.subtotal !== 0) result.push(pseudoActiveKidItem);
      if (pseudoClubDiscount.subtotal !== 0) result.push(pseudoClubDiscount);
      if (pseudoAssociationDiscount.subtotal !== 0) result.push(pseudoAssociationDiscount);
      if (pseudoProgramSettingsDiscount.subtotal !== 0) result.push(pseudoProgramSettingsDiscount);
      if (pseudoProgramDiscount.subtotal !== 0) result.push(pseudoProgramDiscount);

      return result;
    },
    // total() {
    //   // Calc Total Checkout Price
    //   const total = totalCheckout(this.completed);
    //   return total > 0;
    // },
    containsTfa() {
      return !!this.completed.find((rego) => rego.entity.orgtree.national._id === 31);
    },
    containsNrl() {
      return !!this.completed.find(
        (rego) => !rego.entity.orgtree.national || rego.entity.orgtree.national._id === 32
      );
    },
  },
  data() {
    return {
      Math,
      termsAccepted: false,
    };
  },
  methods: {
    ...mapMutations("registration", ["CLEAR_COMPLETED"]),
    ...mapActions("registration", ["updateCompleted"]),
    getNotEmptyAddonsForRego,
    checkoutLines(rego) {
      return [
        {
          name: "Season Registration",
          qty: 1,
          subtotal: rego.priceItem.totalPrice,
        },
      ];
    },
    getRegoDiscounts(rego) {
      const discounts = Object.keys(rego.priceItem.pricing)
        .map((levelName) => {
          const total = totalDiscountsLevel(rego, this.completed, levelName);
          return {
            isDiscount: true,
            name: `${levelName} Registration Discounts`,
            qty: 0,
            subtotal: total,
            level: levelName,
          };
        })
        .filter((d) => d.subtotal !== 0);

      // handle program discount voucher
      const programSettingsDiscountAmount = programSettingsDiscountVoucherDiscounts(rego);
      if (programSettingsDiscountAmount !== 0) {
        discounts.push({
          isDiscount: true,
          name: "Program Voucher",
          qty: 0,
          level: "program",
          subtotal: programSettingsDiscountAmount,
          remove: () => {
            this.updateCompleted({
              rego,
              update: {
                programSettingsDiscountVoucher: undefined,
              },
            });
          },
        });
      }

      // handle program discount voucher
      const programDiscountAmount = programDiscountVoucherDiscounts(rego);
      if (programDiscountAmount !== 0) {
        discounts.push({
          isDiscount: true,
          name: "Program Voucher",
          qty: 0,
          level: "program",
          subtotal: programDiscountAmount,
          remove: () => {
            this.updateCompleted({
              rego,
              update: {
                programDiscountVoucher: undefined,
              },
            });
          },
        });
      }

      // handle NSW vouncher
      const activeKidsDiscountAmount = activeKidsVoucherDiscounts(rego, this.completed);
      if (activeKidsDiscountAmount !== 0) {
        discounts.push({
          isDiscount: true,
          name: "Active Kids Voucher",
          qty: rego.activeKidsVoucher && rego.activeKidsVoucher.length,
          level: "club",
          subtotal: activeKidsDiscountAmount,
          remove: () => {
            this.updateCompleted({
              rego,
              update: {
                activeKidsVoucher: undefined,
              },
            });
          },
        });
      }

      const clubDiscountAmount = clubDiscountVoucherDiscounts(rego, this.completed);
      if (clubDiscountAmount !== 0) {
        discounts.push({
          isDiscount: true,
          name: "Club Discount Code",
          qty: 0,
          subtotal: clubDiscountAmount,
          level: "club",
          remove: () => {
            this.updateCompleted({
              rego,
              update: {
                clubDiscountVoucher: undefined,
              },
            });
          },
        });
      }

      let associationDiscountAmount = associationDiscountVoucherDiscounts(rego, this.completed);

      if (rego.entity.orgtree.national._id === 31) {
        associationDiscountAmount =
          associationDiscountAmount > activeKidsDiscountAmount
            ? associationDiscountAmount - activeKidsDiscountAmount
            : associationDiscountAmount;
      }

      if (associationDiscountAmount !== 0) {
        discounts.push({
          isDiscount: true,
          name: "Association Discount Code",
          qty: 0,
          subtotal: associationDiscountAmount,
          level: "association",
          remove: () => {
            this.updateCompleted({
              rego,
              update: {
                associationDiscountVoucher: undefined,
              },
            });
          },
        });
      }
      return discounts;
    },
    choose(paymentMethod) {
      if (this.termsAccepted) {
        switch (paymentMethod) {
          case "creditcard":
            this.$router.push({ name: "checkout" });
            break;
          case "paynotreq":
            this.$store.commit("root/LOADING", true);
            this.$http
              .post("/nrl/api/v1/portal/registration/register", {
                registrations: this.finishedRegoList,
                paymentTotal: this.checkoutTotal,
                paid: false,
              })
              .then(() => {
                this.CLEAR_COMPLETED();
                this.$store.commit("root/LOADING", false);
                this.$router.push({ name: "paymentcomplete" });
              })
              .catch((error) => {
                this.$store.commit("root/LOADING", false);
                this.$store.commit("views/PUSH_NOTIFICATION", {
                  msg:
                    error.response && error.response.data && error.response.data.message
                      ? error.response.data.message
                      : msg.error.apiError,
                  type: "warning",
                });
                window.scrollTo(0, 0);
              });
            break;
          case "none":
            this.$store.commit("root/LOADING", true);
            this.$http
              .post("/nrl/api/v1/portal/registration/register", {
                registrations: this.finishedRegoList,
                paymentTotal: 0,
                paid: true,
              })
              .then(() => {
                this.CLEAR_COMPLETED();
                this.$store.commit("root/LOADING", false);
                this.$router.push({ name: "paymentcomplete" });
              })
              .catch((error) => {
                this.$store.commit("root/LOADING", false);
                this.$store.commit("views/PUSH_NOTIFICATION", {
                  msg:
                    error.response && error.response.data && error.response.data.message
                      ? error.response.data.message
                      : msg.error.apiError,
                  type: "warning",
                });
                window.scrollTo(0, 0);
              });
            break;
          default:
            this.$store.commit("views/PUSH_NOTIFICATION", {
              msg: msg.error.generic,
              type: "warning",
            });
        }
      } else {
        this.$store.commit("views/PUSH_NOTIFICATION", {
          msg: msg.error.acceptTerms,
          type: "warning",
        });
        window.scrollTo(0, 0);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
