<template>
  <el-card class="full-width mt-1 align-text-center">
    <h3 class="no-margin">{{ title }}</h3>
    <p class="no-margin">{{ subtitle }}</p>
    <hr v-if="title" />
    <div
      v-for="(line, index) in [...lines, ...addon]"
      :key="'line' + String(index)"
      class="d-flex checkout-item"
    >
      <p class="no-margin capitalize">
        <i class="el-icon-close clickable red" v-if="line.remove" @click="line.remove" />
        {{ line.qty ? line.qty + " x " : "" }} {{ line.name }}
      </p>
      <div class="flex-last">
        {{ displayAmount(line) }}
      </div>
    </div>

    <div
      v-for="(line, index) in discounts"
      :key="'discountLine' + String(index)"
      class="d-flex checkout-item"
    >
      <p class="no-margin capitalize">
        <i class="el-icon-close clickable red" v-if="line.remove" @click="line.remove" />
        {{ line.qty ? line.qty + " x " : "" }} {{ line.name }}
      </p>
      <div class="flex-last green">
        {{ displayAmount(line) }}
      </div>
    </div>

    <div v-if="total" class="d-flex checkout-item total">
      <h4 class="no-margin">Total</h4>
      <div id="total" class="flex-last">{{ formatMoney(totalAmount) }}</div>
    </div>

    <!-------------------- Active Kids Voucher Start ----------------->
    <div v-if="showActiveKids && !expanded.activeKidsVoucherForm" class="no-padding">
      <a href="#" @click.prevent="switcher('activeKidsVoucherForm')">Add Active Kids Voucher</a>
    </div>
    <div v-if="expanded.activeKidsVoucherForm" class="no-padding mt-0">
      <h4>Active Kids Voucher</h4>
      <div class="d-flex-column mw-700">
        <SuperInput
          :field="activeKidsVoucherForm.barcode"
          v-model="activeKidsVoucherForm.barcode.value"
          :errors.sync="activeKidsVoucherForm.barcode.errors"
        />
      </div>
      <div class="d-flex-column">
        <el-button
          class="mw-400 full-width mt-1 button-transparent"
          plain
          @click="validate(rego, 'activeKidsVoucherForm')"
        >
          Add
        </el-button>
        <a class="mw-400 full-width" href="#" @click.prevent="switcher('activeKidsVoucherForm')">
          Cancel
        </a>
      </div>
    </div>
    <!-------------------- Active Kids Voucher END  ----------------->

    <!-------------------- Association Voucher Start ----------------->

    <div
      v-if="
        showDiscountOptions &&
        !expanded.associationVoucherForm &&
        rego &&
        rego.entity &&
        rego.entity.orgtree &&
        rego.entity.orgtree.national &&
        rego.entity.orgtree.national._id === 31 &&
        rego.type !== 'referee' &&
        rego.type !== 'volunteer'
      "
      class="no-padding"
    >
      <a id="addAssociationVoucher" href="#" @click.prevent="switcher('associationVoucherForm')"
        >Add Association Voucher</a
      >
    </div>

    <div v-if="expanded.associationVoucherForm" class="no-padding mt-0">
      <h4>Association Voucher</h4>
      <div class="d-flex-column mw-700">
        <SuperInput
          id="voucherNumber"
          :field="associationVoucherForm.barcode"
          v-model="associationVoucherForm.barcode.value"
          :errors.sync="associationVoucherForm.barcode.errors"
        />
      </div>
      <div class="d-flex-column">
        <el-button
          id="addVoucher"
          class="mw-400 full-width mt-1 button-transparent"
          plain
          @click="validate(rego, 'associationVoucherForm')"
        >
          Add
        </el-button>
        <a class="mw-400 full-width" href="#" @click.prevent="switcher('associationVoucherForm')">
          Cancel
        </a>
      </div>
    </div>

    <div
      v-if="
        showDiscountOptions &&
        !expanded.clubVoucherForm &&
        rego &&
        rego.entity &&
        rego.entity.orgtree &&
        rego.entity.orgtree.national &&
        rego.entity.orgtree.national._id === 31 &&
        rego.entity.entityType === 'team' &&
        rego.entity.orgtree.club &&
        rego.type !== 'referee' &&
        rego.type !== 'volunteer'
      "
      class="no-padding"
    >
      <a id="addClubVoucher" href="#" @click.prevent="switcher('clubVoucherForm')"
        >Add Club Voucher</a
      >
    </div>

    <div v-if="expanded.clubVoucherForm" class="no-padding mt-0">
      <h4>Club Voucher</h4>
      <div class="d-flex-column mw-700">
        <SuperInput
          id="voucherNumber"
          :field="clubVoucherForm.barcode"
          v-model="clubVoucherForm.barcode.value"
          :errors.sync="clubVoucherForm.barcode.errors"
        />
      </div>
      <div class="d-flex-column">
        <el-button
          id="addVoucher"
          class="mw-400 full-width mt-1 button-transparent"
          plain
          @click="validate(rego, 'clubVoucherForm')"
        >
          Add
        </el-button>
        <a class="mw-400 full-width" href="#" @click.prevent="switcher('clubVoucherForm')">
          Cancel
        </a>
      </div>
    </div>
    <!-------------------- Association Voucher END  ----------------->

    <!-------------------- Program Settings Voucher Start ----------------->
    <div
      v-if="
        showDiscountOptions &&
        !expanded.programSettingsVoucherForm &&
        rego &&
        rego.type === 'program' &&
        (rego.entity.orgtree.national._id === 31 || rego.entity.orgtree.national._id === 32)
      "
      class="no-padding"
    >
      <a
        id="addProgramSettingsVoucher"
        href="#"
        @click.prevent="switcher('programSettingsVoucherForm')"
        >{{ rego.entity.settings.type }} Discount</a
      >
    </div>

    <div v-if="expanded.programSettingsVoucherForm" class="no-padding mt-0">
      <h4>{{ rego.entity.settings.type }} Discount</h4>
      <div class="d-flex-column mw-700">
        <SuperInput
          id="voucherNumber"
          :field="programSettingsVoucherForm.barcode"
          v-model="programSettingsVoucherForm.barcode.value"
          :errors.sync="programSettingsVoucherForm.barcode.errors"
        />
      </div>
      <div class="d-flex-column">
        <el-button
          id="addVoucher"
          class="mw-400 full-width mt-1 button-transparent"
          plain
          @click="validate(rego, 'programSettingsVoucherForm')"
        >
          Add
        </el-button>
        <a
          class="mw-400 full-width"
          href="#"
          @click.prevent="switcher('programSettingsVoucherForm')"
        >
          Cancel
        </a>
      </div>
    </div>
    <!-------------------- Program Settings END  ----------------->

    <!-------------------- Program Voucher Start ----------------->
    <div
      v-if="
        showDiscountOptions &&
        !expanded.programVoucherForm &&
        rego &&
        rego.type === 'program' &&
        (rego.entity.orgtree.national._id === 31 || rego.entity.orgtree.national._id === 32)
      "
      class="no-padding"
    >
      <a id="addProgramVoucher" href="#" @click="switcher('programVoucherForm')"
        >{{ rego.entity.name }} Discount</a
      >
    </div>

    <div v-if="expanded.programVoucherForm" class="no-padding mt-0">
      <h4>{{ rego.entity.name }} Discount</h4>
      <div class="d-flex-column mw-700">
        <SuperInput
          id="voucherNumber"
          :field="programVoucherForm.barcode"
          v-model="programVoucherForm.barcode.value"
          :errors.sync="programVoucherForm.barcode.errors"
        />
      </div>
      <div class="d-flex-column">
        <el-button
          id="addVoucher"
          class="mw-400 full-width mt-1 button-transparent"
          plain
          @click="validate(rego, 'programVoucherForm')"
        >
          Add
        </el-button>
        <a class="mw-400 full-width" href="#" @click.prevent="switcher('programVoucherForm')">
          Cancel
        </a>
      </div>
    </div>
    <!-------------------- Program  END  ----------------->

    <!-------------------- StatEdge Discount Code Start ----------------->
    <div
      v-if="showDiscountOptions && rego.entity.entityType === 'club' && !expanded.clubDiscountForm"
      class="no-padding mt-0"
    >
      <a href="#" @click.prevent="switcher('clubDiscountForm')">Add Club Discount</a>
    </div>
    <div v-if="expanded.clubDiscountForm" class="no-padding mt-0">
      <h4>Club Discount Code</h4>
      <div class="d-flex-column mw-700">
        <SuperInput
          :field="clubDiscountForm.barcode"
          v-model="clubDiscountForm.barcode.value"
          :errors.sync="clubDiscountForm.barcode.errors"
        />
      </div>
      <div class="d-flex-column">
        <el-button
          class="mw-400 full-width mt-1 button-transparent"
          plain
          @click="validate(rego, 'clubDiscountForm')"
        >
          Add
        </el-button>
        <a class="mw-400 full-width" href="#" @click.prevent="switcher('clubDiscountForm')">
          Cancel
        </a>
      </div>
    </div>
    <!-------------------- StatEdge Discount Code End -------------------->
  </el-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import SuperInput from "@/components/form/fields/SuperInput.vue";
import { flatten, makeForm } from "@/utils/forms";
import endpoints from "@/utils/constants/api";
import { formatMoney } from "@/utils/money";
import msg from "@/utils/constants/msg";
import { isBelowAge } from "@/utils/date/minor";

export default {
  name: "CheckOutItemsCard",
  components: { SuperInput },
  data() {
    return {
      expanded: {
        associationVoucherForm: false,
        clubVoucherForm: false,
        programSettingsVoucherForm: false,
        programVoucherForm: false,
        activeKidsVoucherForm: false,
        clubDiscountForm: false,
      },
      associationVoucherForm: makeForm({
        barcode: {
          label: "Voucher Number",
        },
      }),
      clubVoucherForm: makeForm({
        barcode: {
          label: "Voucher Number",
        },
      }),
      programSettingsVoucherForm: makeForm({
        barcode: {
          label: "Voucher Number",
        },
      }),
      programVoucherForm: makeForm({
        barcode: {
          label: "Voucher Number",
        },
      }),
      activeKidsVoucherForm: makeForm({
        barcode: {
          label: "Voucher Code",
        },
      }),
      clubDiscountForm: makeForm({
        barcode: {
          label: "Discount Code",
        },
      }),
    };
  },
  mounted() {},
  methods: {
    ...mapActions("views", ["notify"]),
    ...mapActions("registration", ["updateCompleted"]),
    async validate(rego, form) {
      this.$store.commit("root/LOADING", true);
      const flattenedForm = flatten(this[form]);
      let endpointType;
      let discountObject;
      let discountObjectReference;
      let result;
      let entityId = this.entityId;
      const { email } = this;
      if (
        (this.rego.type === "club" && this.totalClubAmount <= 0) ||
        (this.rego.type === "program" && this.rego.priceItem.totalPrice <= 0)
      ) {
        this.$store.commit("root/LOADING", false);
        return this.notify({
          autoClose: true,
          msg: msg.error.voucherNotNeeded,
          type: "warning",
          window,
        });
      }

      // Switch statement determines which endpoint to call
      // and prepare the related data structure to store in the registration/complated
      switch (form) {
        case "activeKidsVoucherForm":
          endpointType = "activensw";
          const currentActiveVoucher = this.rego.activeKidsVoucher;
          let prevVoucher;
          if (currentActiveVoucher && currentActiveVoucher.length)
            prevVoucher = currentActiveVoucher[currentActiveVoucher.length - 1];
          const discountVouchers = prevVoucher ? [prevVoucher] : [];
          discountVouchers.push({
            barcode: flattenedForm.barcode,
            pin: flattenedForm.pin,
            balance: 0,
          });
          discountObject = {
            activeKidsVoucher: discountVouchers,
          };
          // Hook to prevent same voucher code use
          const flattenedFormBarcode =
            flattenedForm.barcode && flattenedForm.barcode.toLowerCase().substring(0, 10);
          if (
            this.completed.some((r) => {
              const activeKidsVoucherBarcodes =
                r.activeKidsVoucher &&
                Array.isArray(r.activeKidsVoucher) &&
                r.activeKidsVoucher.length
                  ? r.activeKidsVoucher.map(
                      (v) => v.barcode && v.barcode.toLowerCase().substring(0, 10)
                    )
                  : undefined;
              return (
                activeKidsVoucherBarcodes &&
                activeKidsVoucherBarcodes.includes(flattenedFormBarcode)
              );
            })
          ) {
            this.$store.commit("root/LOADING", false);
            return this.notify({
              autoClose: true,
              msg: msg.error.activeKidsInUse,
              type: "warning",
              window,
            });
          }
          discountObjectReference = discountObject.activeKidsVoucher;
          break;
        case "clubDiscountForm":
          endpointType = "statedge";
          discountObject = {
            clubDiscountVoucher: {
              barcode: flattenedForm.barcode,
              balance: 0,
            },
          };
          discountObjectReference = discountObject.clubDiscountVoucher;
          // Hook to prevent same discount code use
          if (
            this.completed
              .map((r) => r.clubDiscountVoucher && r.clubDiscountVoucher.barcode)
              .includes(flattenedForm.barcode)
          ) {
            this.$store.commit("root/LOADING", false);
            return this.notify({
              autoClose: true,
              msg: msg.error.statedgeDiscountInUse,
              type: "warning",
              window,
            });
          }
          break;
        case "associationVoucherForm":
          endpointType = "statedge";
          discountObject = {
            associationDiscountVoucher: {
              barcode: flattenedForm.barcode,
              balance: 0,
            },
          };
          if (rego && rego.entity && rego.entity.association)
            entityId = rego.entity.association._id;
          discountObjectReference = discountObject.associationDiscountVoucher;
          // Hook to prevent same discount code use
          if (
            this.completed
              .map((r) => r.associationDiscountVoucher && r.associationDiscountVoucher.barcode)
              .includes(flattenedForm.barcode)
          ) {
            this.$store.commit("root/LOADING", false);
            return this.notify({
              autoClose: true,
              msg: msg.error.statedgeDiscountInUse,
              type: "warning",
              window,
            });
          }
          break;
        case "clubVoucherForm":
          endpointType = "statedge";
          discountObject = {
            clubDiscountVoucher: {
              barcode: flattenedForm.barcode,
              balance: 0,
            },
          };

          if (rego && rego.entity && rego.entity.orgtree.club)
            entityId = rego.entity.orgtree.club._id;
          discountObjectReference = discountObject.clubDiscountVoucher;
          // Hook to prevent same discount code use
          if (
            this.completed
              .map((r) => r.clubDiscountVoucher && r.clubDiscountVoucher.barcode)
              .includes(flattenedForm.barcode)
          ) {
            this.$store.commit("root/LOADING", false);
            return this.notify({
              autoClose: true,
              msg: msg.error.statedgeDiscountInUse,
              type: "warning",
              window,
            });
          }
          break;
        case "programSettingsVoucherForm":
          endpointType = "programsettings";
          discountObject = {
            programSettingsDiscountVoucher: {
              barcode: flattenedForm.barcode,
              balance: 0,
            },
          };
          discountObjectReference = discountObject.programSettingsDiscountVoucher;
          // Hook to prevent same discount code use
          // if (
          //   this.completed
          //     .map((r) => r.programSettingsDiscountVoucher && r.programSettingsDiscountVoucher.barcode)
          //     .includes(flattenedForm.barcode)
          // ) {
          //   this.$store.commit("root/LOADING", false);
          //   return this.notify({
          //     autoClose: true,
          //     msg: msg.error.statedgeDiscountInUse,
          //     type: "warning",
          //     window,
          //   });
          // }
          break;
        case "programVoucherForm":
          endpointType = "program";
          discountObject = {
            programDiscountVoucher: {
              barcode: flattenedForm.barcode,
              balance: 0,
            },
          };
          discountObjectReference = discountObject.programDiscountVoucher;
          break;
        default:
          break;
      }

      try {
        const bId =
          (this.rego &&
            this.rego.entity &&
            this.rego.entity.meta &&
            this.rego.entity.meta.akv &&
            this.rego.entity.meta.akv.businessId) ||
          (this.rego &&
            this.rego.entity &&
            this.rego.entity.association &&
            this.rego.entity.association.meta &&
            this.rego.entity.association.meta.akv &&
            this.rego.entity.association.meta.akv.businessId);
        const data =
          endpointType === "activensw"
            ? {
                code: flattenedForm.barcode,
                businessId: bId,
              }
            : { entityId, discountEmail: email, ...flattenedForm };

        result = await this.$http.post(endpoints.voucher[endpointType], data);
        const { data: balance } = result.data;
        if (balance && balance > 0) {
          // Valid Voucher
          const clubFeeInfo = this.addon.find((element) => element.category === "club");
          let clubPrice = 0;
          if (this.rego.priceItem.pricing.club) {
            clubPrice = this.rego.priceItem.pricing.club.price;
          }
          let clubValue = clubPrice;
          if (clubFeeInfo) clubValue = clubPrice + clubFeeInfo.price;
          if (form === "clubVoucherForm" && clubValue < balance) {
            discountObjectReference.balance = clubValue;
          } else if (form === "activeKidsVoucherForm") {
            let i = -1;
            const currentVoucher = discountObjectReference.find((r) => {
              i++;
              return r.barcode === flattenedForm.barcode;
            });
            if (currentVoucher) {
              currentVoucher.balance = balance;
              discountObjectReference.splice(i, 1, currentVoucher);
            }
            discountObjectReference.balance = balance;
          } else {
            discountObjectReference.balance = balance;
          }

          this.updateCompleted({
            rego: this.rego,
            update: discountObject,
          });
          this.switcher(form);
          if (form === "activeKidsVoucherForm")
            this.activeKidsVoucherForm.barcode.value = undefined;
          this.notify({
            autoClose: true,
            msg:
              endpointType === "activensw"
                ? msg.success.activeKidsAdded
                : msg.success.statedgeDiscountAdded,
            window,
          });
        }
      } catch (error) {
        // Invalid Voucher
        this.notify({
          autoClose: false,
          msg:
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : msg.error.apiError,
          type: "warning",
          window,
        });
      }
      return this.$store.commit("root/LOADING", false);
    },
    switcher(key) {
      this.expanded[key] = !this.expanded[key];
    },
    displayAmount(line) {
      if (line.isDiscount)
        return line.subtotal
          ? `-${formatMoney(line.subtotal)}`
          : `-${formatMoney(line.price * line.qty)}`;
      return line.subtotal ? formatMoney(line.subtotal) : formatMoney(line.price * line.qty);
    },
    formatMoney,
    isBelowAge,
  },
  props: {
    email: {
      type: String,
      required: false,
    },
    entityId: {
      type: Number,
    },
    checkoutTotal: {
      type: Number,
    },
    rego: {
      type: Object,
    },
    addon: {
      type: Array,
    },
    total: {
      // Determines whether the `total` row is shown
      type: Boolean,
      default: false,
    },
    discounts: {
      type: Array,
    },
    showDiscountOptions: {
      // Determines whether the discount/active kids are shown
      type: Boolean,
      default: false,
    },
    lines: Array,
    title: {
      type: String,
      required: false,
    },
    subtitle: {
      type: String,
      required: false,
    },
  },
  computed: {
    ...mapGetters("registration", ["completed", "current"]),
    program() {
      return this.rego && this.rego.entity && this.rego.entity.entityType === "program";
    },
    team() {
      return this.rego && this.rego.entity && this.rego.entity.entityType === "team";
    },
    totalAmount() {
      return this.checkoutTotal;
    },
    programTotal() {
      const balanceAddons = this.addon.reduce(
        (accumulator, { price, qty }) => accumulator + price * qty,
        0
      );
      const balanceLines = this.lines.reduce(
        (accumulator, { subtotal, qty }) => accumulator + subtotal * qty,
        0
      );
      const discountTotal = this.discounts.reduce(
        (accumulator, { subtotal, qty }) => accumulator + subtotal * qty,
        0
      );
      return balanceAddons + balanceLines - discountTotal;
    },
    totalClubAmount() {
      if (this.rego.priceItem.pricing.club) {
        const clubPrice = this.rego.priceItem.pricing.club.price;
        const clubAddon =
          this.rego.priceItem.pricing.club.saleableItems.length > 0
            ? this.rego.priceItem.pricing.club.saleableItems.reduce(
                (acc, item) => (item.qty > 0 ? acc + item.qty * item.price : acc),
                0
              )
            : 0;
        const discountTotal = this.discounts.reduce(
          (acc, line) => (line.level === "club" ? acc + line.subtotal : acc),
          0
        );
        return clubPrice + clubAddon - discountTotal;
      }
      return 0;
    },
    totalTeamAmount() {
      let total = 0;
      if (this.rego.priceItem.pricing.competition) {
        total += this.rego.priceItem.pricing.competition.price;
        total +=
          this.rego.priceItem.pricing.competition.saleableItems.length > 0
            ? this.rego.priceItem.pricing.competition.saleableItems.reduce(
                (acc, item) => (item.qty > 0 ? acc + item.qty * item.price : acc),
                0
              )
            : 0;
        total += this.discounts.reduce(
          (acc, line) => (line.level === "competition" ? acc + line.subtotal : acc),
          0
        );
      }
      if (this.rego.priceItem.pricing.association) {
        total += this.rego.priceItem.pricing.association.price;
        total +=
          this.rego.priceItem.pricing.association.saleableItems.length > 0
            ? this.rego.priceItem.pricing.association.saleableItems.reduce(
                (acc, item) => (item.qty > 0 ? acc + item.qty * item.price : acc),
                0
              )
            : 0;
        total += this.discounts.reduce(
          (acc, line) => (line.level === "association" ? acc + line.subtotal : acc),
          0
        );
      }
      return total;
    },
    showActiveKids() {
      // Determines whether the active kids are shown
      if (
        this.showDiscountOptions &&
        this.totalClubAmount > 0 &&
        this.rego.entity.entityType === "club" &&
        this.rego.entity.meta.akv &&
        this.rego.entity.meta.akv.businessId &&
        this.rego &&
        this.rego.profile.participantForm.dob &&
        this.isBelowAge(this.rego.profile.participantForm.dob, 20) &&
        this.rego.entity.meta &&
        this.rego.entity.meta.akv &&
        this.rego.entity.meta.akv.businessId
      ) {
        return true;
      }
      if (
        this.rego &&
        this.rego.entity &&
        this.rego.entity.entityType === "program" &&
        this.rego.entity.meta.akv &&
        this.rego.entity.meta.akv.businessId &&
        this.rego.profile.participantForm.dob &&
        this.isBelowAge(this.rego.profile.participantForm.dob, 20) &&
        this.rego.entity.settings.activeKidsVoucher &&
        this.programTotal > 0
      ) {
        return true;
      }
      if (
        this.showDiscountOptions &&
        this.totalTeamAmount > 0 &&
        this.rego.entity.entityType === "team" &&
        this.rego &&
        this.rego.profile.participantForm.dob &&
        this.isBelowAge(this.rego.profile.participantForm.dob, 20) &&
        this.rego.entity.association.meta &&
        this.rego.entity.association.meta.akv &&
        this.rego.entity.association.meta.akv.businessId
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
hr {
  border: 0.5px solid rgba(#d8d8d8, 0.7);
}

::v-deep .el-card__body {
  & > div {
    margin-top: 15px;
    padding-bottom: 10px;
  }
}

.checkout-item {
  border-bottom: 1px solid rgba(#d8d8d8, 0.7);

  &.total {
    margin-top: 0 !important;
    padding-top: 10px;
    border-bottom: none !important;
    border-top: 1px solid #222222;
  }
}

.red {
  color: red;
}

.green {
  color: $secondary;
}

.capitalize {
  text-transform: capitalize;
}
</style>
